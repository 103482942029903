import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="frame-loader"
export default class extends Controller {
  handleDropdownChange(e) {
    const { url, frameId, paramFieldName  } = e.target.dataset;

    if (frameId) {
      const frame = document.getElementById(frameId);

      if (frame && url) {
        const value = e.target.value;

        if (value) {
          const params = new URLSearchParams();
          params.append(paramFieldName, e.target.value);

          let paramsConnector = url.includes('?') ? '&' : '?';
          frame.src = `${url}${paramsConnector}${params}`;
        } else {
          frame.innerHTML = '';
        }
      }
    }
  }
}
