import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';

export default class extends Controller {
  static targets = ["container", "pagination", "buttonNext", "buttonPrev"];

  connect() {
    this.swiper = new Swiper(this.containerTarget, {
      modules: [Navigation, Pagination],
      slidesPerView: 'auto',
      slidesPerGroup: 2,
      spaceBetween: 25,
      centerInsufficientSlides: true,
      loop: false,
      watchOverflow: true,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      navigation: {
        nextEl: this.buttonNextTarget,
        prevEl: this.buttonPrevTarget,
      },
      on: {
        slideChangeTransitionEnd: this.handleSlideChangeTransitionEnd.bind(this)
      },
    });

    this.swiper.slides.forEach((slide) => {
      slide.addEventListener('click', this.handleSlideClick.bind(this));
    });
  }

  handleSlideChangeTransitionEnd(swiper) {
    if (!swiper.clickedSlide) {
      var activeSlide = $(".swiper-slide-active").find(".report-assessment-images");

      if (activeSlide.length) {
        reportHandler.setReportContentTabAsActive(activeSlide);
      }
    }
  }

  handleSlideClick(event) {
    const clickedSlide = event.currentTarget;
    const slideRect = clickedSlide.getBoundingClientRect();
    const containerRect = this.containerTarget.getBoundingClientRect();

    if (slideRect.left < containerRect.left || slideRect.right > containerRect.right) {
      this.swiper.slideTo(this.swiper.slides.indexOf(clickedSlide), 300);
    }
  }
}
