import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    const section = event.currentTarget;
    const isExpanded = section.getAttribute("aria-expanded") === 'true';

    section.setAttribute("aria-expanded", !isExpanded);
  }

  toggleAll(event) {
    const isExpanded = event.currentTarget.dataset.expanded === 'true';

    $(this.element).find('.accordeon-section').attr("aria-expanded", isExpanded);
  }
}