import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    const id = this.element.getAttribute('data-block-id')
    const timeout = this.element.getAttribute('data-timeout')
    const block = document.getElementById(id)

    if (block) {
      if (timeout) {
        setTimeout(() => { this.scrollToBottom(block) }, timeout)
      } else {
        this.scrollToBottom(block)
      }
    }

    this.scrollElementIntoView();
  }

  scrollToBottom (block) {
    block.scrollTop = block.scrollHeight
  }

  scrollElementIntoView() {
    const scrollIntoViewAttr = this.element.getAttribute('data-scroll-into-view');

    if (scrollIntoViewAttr === 'true') {
      setTimeout(() => { this.element.scrollIntoView() }, 0);
    }
  }
}
