import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="communication"
export default class extends Controller {
  static values = { title: String, count: Number }

  countValueChanged() {
    this.element.innerHTML = `${this.titleValue} (${this.countValue})`
  }
}
