import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stream-script"
export default class extends Controller {
  connect() {
    const scripts = this.element.getElementsByTagName('script');

    for (let script of scripts) {
      let newScript = document.createElement("script");
      newScript.innerHTML = script.innerHTML;
      document.body.appendChild(newScript);
    }
  }
}
